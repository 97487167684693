<template>
  <div class="Calculator">
    <div class="Calculator_bac"></div>
    <div class="Calculator_title">
      <h3>薪税计算器</h3>
      <p>智能分配，为您节税</p>
    </div>
    <div class="Calculator_box">
      <div class="Calculator_content">
        <h2>收入详情</h2>
        <van-cell title="个人税前收入">
          <template #right-icon>
            <div>{{ number | toMoney }} 元</div>
          </template>
        </van-cell>
        <div class="payment_ratio">
          <table class="payment_ratio_table">
            <thead>
              <tr>
                <th></th>
                <th>个人税后收入</th>
                <th>应预扣缴税</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in list" :key="item.name">
                <td>{{ item.name }}</td>
                <td>
                  {{ item.afterTax | toMoney }}
                  元
                </td>
                <td>{{ item.payTax | toMoney }}元</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="total_list">
          <van-cell title="个人税后总额">
            <template #right-icon>
              <div>{{ totalAfterTax | toMoney }} 元</div>
            </template>
          </van-cell>
          <van-cell title="应预扣缴税总额">
            <template #right-icon>
              <div>{{ totalTaxDeduction | toMoney }} 元</div>
            </template>
          </van-cell>
          <van-cell title="个人缴纳公积金">
            <template #right-icon>
              <div>
                {{ (Math.round(providentFundVal * 12 * 100) / 100) | toMoney }}
                元
              </div>
            </template>
          </van-cell>
          <van-cell title="公司缴纳公积金">
            <template #right-icon>
              <div>
                {{
                  (Math.round(totalCompanyProvidentFund * 12 * 100) / 100)
                    | toMoney
                }}
                元
              </div>
            </template>
          </van-cell>
          <van-cell title="个人缴纳社保">
            <template #right-icon>
              <div>
                {{ (Math.round(socialSecurityVal * 12 * 100) / 100) | toMoney }}
                元
              </div>
            </template>
          </van-cell>
          <van-cell title="公司缴纳社保">
            <template #right-icon>
              <div>
                {{
                (Math.round(totalCompanySocialSecurity * 12 * 100) / 100)
                | toMoney
                }}
                元
              </div>
            </template>
          </van-cell>
          <van-cell title="专项扣除免税总额">
            <template #right-icon>
              <div>
                {{ (Math.round(specialVal * 12 * 100) / 100) | toMoney }} 元
              </div>
            </template>
          </van-cell>
          <van-cell title="累计个人养老金">
            <template #right-icon>
              <div>
                {{ (Math.round(personalElderlyTotalVal * 100) / 100) | toMoney }} 元
              </div>
            </template>
          </van-cell>
        </div>
        <p class="notes">注：计算结果仅供参考</p>
        <div class="button_box">
          <van-button type="info" @click="comeBackClick">返回</van-button>
        </div>
      </div>
    </div>
    <copyrightIp />
  </div>
</template>
<script>
import { Cell, CellGroup, Button } from "vant";
import { findALLTTaxRateMonth } from "@/api/Calculator";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [Cell.name]: Cell,
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    copyrightIp,
  },
  data() {
    return {
      number: 0, //税前收入
      socialSecurityVal: 0, // 社保缴纳
      totalCompanyProvidentFund: 0, //公司缴纳公积金
      providentFundVal: 0, // 公积金缴纳
      totalCompanySocialSecurity: 0, //公司缴纳社保
        specialVal: 0, // 各项专税
        personalElderlyTotalVal: 0, // 累计个人养老金
      VulnerabilityList: [], // 税率
      list: [
        {
          name: "第1个月",
          afterTax: "",
          payTax: "",
        },
        {
          name: "第2个月",
          afterTax: "",
          payTax: "",
        },
        {
          name: "第3个月",
          afterTax: "",
          payTax: "",
        },
        {
          name: "第4个月",
          afterTax: "",
          payTax: "",
        },
        {
          name: "第5个月",
          afterTax: "",
          payTax: "",
        },
        {
          name: "第6个月",
          afterTax: "",
          payTax: "",
        },
        {
          name: "第7个月",
          afterTax: "",
          payTax: "",
        },
        {
          name: "第8个月",
          afterTax: "",
          payTax: "",
        },
        {
          name: "第9个月",
          afterTax: "",
          payTax: "",
        },
        {
          name: "第10个月",
          afterTax: "",
          payTax: "",
        },
        {
          name: "第11个月",
          afterTax: "",
          payTax: "",
        },
        {
          name: "第12个月",
          afterTax: "",
          payTax: "",
        },
      ],
    };
  },
  filters: {
    // 首字母大写过滤器
    toMoney: function (value) {
      if (!value) return "0.00";
      value = value.toLocaleString("zh", {
        style: "decimal",
        minimumFractionDigits: 2,
      });
      return value;
    },
  },
  computed: {
    //个人税后总额
    totalAfterTax: function () {
      let num = 0;
      this.list.forEach((item) => {
        num += Number(item.afterTax);
      });
      num = Math.round(num * 100) / 100;
      return num;
    },
    //应预扣缴税总额
    totalTaxDeduction: function () {
      let num = 0;
      this.list.forEach((item) => {
        num += Number(item.payTax);
      });
      num = Math.round(num * 100) / 100;
      return num;
    },
  },
  mounted() {
      this.getFindALLTTaxRateMonth();
      this.number = Number(this.$route.query.number) || 0;
      this.socialSecurityVal = Number(this.$route.query.socialSecurityVal) || 0;
      this.totalCompanyProvidentFund =
          Number(this.$route.query.providentFundUnitVal) || 0;
      this.providentFundVal = Number(this.$route.query.providentFundVal) || 0;
      this.totalCompanySocialSecurity =
          Number(this.$route.query.socialSecurityUnitVal) || 0;

      this.specialVal = Number(this.$route.query.specialVal) || 0;
      this.personalElderlyTotalVal = Number(this.$route.query.personalElderlyTotalVal) || 0;
  },
  methods: {
    //   返回
    comeBackClick() {
      this.$router.go(-1);
    },
    // 初始化表格
    income() {
      let total = 0;
      this.list.forEach((item, i) => {
        let index = i + 1;
        this.personalElderlyTotalVal=this.personalElderlyTotalVal<0?0:this.personalElderlyTotalVal;
        this.personalElderlyTotalVal=this.personalElderlyTotalVal>12000?12000:this.personalElderlyTotalVal;
        let payTax =
          this.number * index -
          this.socialSecurityVal * index -
          this.providentFundVal * index -
          this.specialVal * index -
          5000 * index-(Math.round(this.personalElderlyTotalVal/12,2) * index);
        payTax = payTax > 0 ? payTax : 0;
        let data = this.computeTaxRate(payTax);
        item.payTax = payTax * data.sTaxRate - data.sQuickCalculation - total;
        item.payTax = Math.round(item.payTax * 100) / 100;
        item.afterTax =
          this.number -
          this.socialSecurityVal -
          this.providentFundVal -
            Math.round(this.personalElderlyTotalVal/12,2)-
          item.payTax;
        item.afterTax = Math.round(item.afterTax * 100) / 100;
        total += item.payTax;
      });
    },
    // 计算税率
    computeTaxRate(num) {
      let data = this.VulnerabilityList.find((item) => {
        let min = Number(item.sLowerValue);
        let max = Number(item.sUpperValue);
        if (num >= min && num <= max) {
          return item;
        }
      });
      return data;
    },
    //    获取个人所得税
    getFindALLTTaxRateMonth() {
      findALLTTaxRateMonth().then((res) => {
        this.VulnerabilityList = res.data.data;
        this.income();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Calculator {
  width: 100%;
  position: relative;
  // padding-bottom: 70px;
  .Calculator_bac {
    width: 100%;
    height: 250px;
    background-image: url("../../../assets/img/Utilities1_Moon_01.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .Calculator_title {
    padding: 10px 10px 0;
    color: #fff;
    h3 {
      font-size: 20px;
      font-weight: bold;
    }
    p {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  .Calculator_box {
    width: 100%;
    padding: 10px;

    .Calculator_content {
      width: 100%;
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      margin-top: 5px;
      h2 {
        font-weight: bold;
      }
      .van-cell {
        padding: 10px 5px;
      }
    }
  }
}
.payment_ratio {
  width: 100%;
  padding-bottom: 28px;
  padding-top: 20px;
  border-bottom: 1px solid #8f8f8f;
  .payment_ratio_table {
    width: 100%;
    thead {
      tr {
        line-height: 25px;
        border-bottom: 1px solid #efecec;
        th {
          width: 25%;
          text-align: center;
        }
      }
    }
    tbody {
      font-size: 14px;
      tr {
        border-bottom: 1px solid #efecec;
        td {
          width: 25%;
          line-height: 35px;
          text-align: center;
        }
      }
    }
  }
}
.total_list {
  padding-top: 10px;
}
.notes {
  text-align: center;
  font-weight: bold;
  padding: 12px 0;
}
.button_box {
  .van-button {
    width: 100%;
    border-radius: 8px;
  }
}
</style>
