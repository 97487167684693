import request from "@/utils/request";
import qs from "qs";

// 查询所有年终奖扣除税率
export const findAllTTaxRate = (params) => {
  return request({
    url: "wx/findAllTTaxRate",
    method: "get",
    params,
  });
};
// 查询年终奖漏洞区间
export const findAllTLoopholeYearendbonus = (params) => {
  return request({
    url: "wx/findAllTLoopholeYearendbonus",
    method: "get",
    params,
  });
};
// 获取社保缴纳比例
export const findAllTSocialSecurity = (params) => {
  return request({
    url: "wx/findAllTSocialSecurity",
    method: "get",
    params,
  });
};
// 获取公积金
export const findAllTAccumulationFund = (params) => {
  return request({
    url: "wx/findAllTAccumulationFund",
    method: "get",
    params,
  });
};
// 获取个税专项扣除
export const findAllDeductionIndividual = (params) => {
  return request({
    url: "wx/findAllDeductionIndividual",
    method: "get",
    params,
  });
};
// 获取个人所得税
export const findALLTTaxRateMonth = (params) => {
  return request({
    url: "wx/findALLTTaxRateMonth",
    method: "get",
    params,
  });
};
// 计算器等级权限（type 年2、月3）
export const getEquertyCalculatorGarden = (params) => {
  return request({
    url: "wx/getEquertyCalculatorGarden",
    method: "get",
    params,
  });
};
